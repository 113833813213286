import request from '@/until/request.js'
const prefixName = '/gm/webPrdUnit'

// 获取商品类别列表
export function GetProductUnitList(data){
  return request({
    url: prefixName,
    method: 'get',
    params: { ...data }
  })
}
// 创建商品类别
export function AddProductUnit(result){
  return request({
    url: prefixName,
    method: 'post',
    data: result
  })
}
// 修改供应商
export function EditProductUnit(result){
    return request({
        url: prefixName,
        method: 'PUT',
        data: result
    })
}
// 删除供应商
export function DeleteProductUnit(id){
    return request({
        url: prefixName + '/' + id,
        method: 'delete'
    })
}