<template>
  <div class="FormWrap" v-loading="loading">
    <mia-navback @back="back" :isShowSave="true" @submit="submitForm" />
    <div class="content">
      <mia-Card title="基础信息">
        <div style="margin-left: 4px" slot="content">
          <el-form
            ref="basicForm"
            :model="basicForm"
            label-width="120px"
            :rules="rules"
          >
            <el-row :gutter="50">
              <el-col :span="11">
                <el-form-item label="登记证号" prop="regCode" key="regCode">
                  <el-input
                    :disabled="forbid"
                    v-model="basicForm.regCode"
                    style="width: 450px"
                  ></el-input>
                  <el-button
                    icon="el-icon-search"
                    @click="searchProductInfo"
                  ></el-button>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="商品编码" prop="code" key="code">
                  <el-input v-model="basicForm.code"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="商品名称" prop="name" key="name">
                  <el-input v-model="basicForm.name"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="11">
                <el-form-item label="助记码" prop="barCode" key="barCode">
                  <el-input v-model="basicForm.barCode"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="类别" prop="typeId" key="typeId">
                  <el-cascader
                    v-model="basicForm.typeId"
                    style="width: 100%"
                    :props="typeProps"
                    :options="typeList"
                    @change="handleChange"
                  >
                    <template slot-scope="{ node, data }">
                      <span>{{ data.name }}</span>
                    </template>
                  </el-cascader>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="限制性农药" prop="isLimit" key="isLimit">
                  <el-select
                    v-model="basicForm.isLimit"
                    style="width: 100%"
                    filterable
                    clearable
                    placeholder="请选择是否为限制性农药"
                  >
                    <el-option
                      v-for="(item, Index) in LimitList"
                      :key="Index"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item
                  label="保质期(天)"
                  required
                  prop="shelfLife"
                  key="shelfLife"
                >
                  <el-input v-model.number="basicForm.shelfLife"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-row>
                  <el-col :span="24">
                    <el-form-item
                      label="净含量"
                      prop="netContent"
                      key="netContent"
                    >
                      <el-col :span="10">
                        <el-input
                          v-model.number="basicForm.netContent"
                        ></el-input>
                      </el-col>
                      <el-col :span="4">
                        <el-select
                          v-model="basicForm.netContentUnit"
                          style="width: 100%"
                          placeholder="请选择单位"
                        >
                          <el-option
                            v-for="(item, Index) in UnitList"
                            :key="Index"
                            :label="item.name"
                            :value="item.id"
                          />
                        </el-select>
                      </el-col>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </mia-Card>
      <mia-Card title="价格信息">
        <div style="margin-left: 4px" slot="content">
          <el-form
            v-if="!isMultiUnit"
            ref="priceForm"
            :model="priceForm"
            :rules="priceRules"
            label-width="120px"
          >
            <el-row :gutter="50">
              <el-col :span="11">
                <el-form-item label="商品单位" prop="name" key="name">
                  <el-select
                    v-model="priceForm.name"
                    style="width: 100%"
                    filterable
                    placeholder="请选择"
                  >
                    <el-button
                      type="text"
                      style="width: 100%; text-align: left; padding-left: 20px"
                      @click="openUnitDialog"
                      >新增单位</el-button
                    >
                    <el-option
                      v-for="(item, Index) in units"
                      :key="Index"
                      :label="item.name"
                      :value="item.name"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="11" v-if="Config.isMulUnit == '1'">
                <el-form-item label="多计量单位" prop="isMultiUnit">
                  <!-- v-if="Config.isMulUnit" -->
                  <el-checkbox
                    v-model="isMultiUnit"
                    @change="changeUnit"
                  ></el-checkbox>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="辅助单位与基本单位有一定的换算比例。 例如基本单位“瓶“，辅助单位”箱“，1箱=12瓶"
                    placement="right"
                  >
                    <i class="el-icon-warning" style="margin-left: 20px"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <div class="productTabel" style="margin-left: 65px">
                <el-table
                  style="width: 90%"
                  :data="formData.priceTable"
                  ref="priceTable"
                  border
                >
                  <el-table-column align="center" label="零售价" prop="price">
                    <template slot="header">
                      <span style="color: red; margin-right: 5px">*</span
                      ><span>零售价</span>
                    </template>
                    <template slot-scope="scope">
                      <el-form-item prop="price">
                        <el-input-number
                          size="mini"
                          v-model="priceForm.price"
                          controls-position="right"
                          :min="0.0"
                          :max="99999.99"
                          :precision="2"
                          placeholder="请录入"
                          @change="Pricechange"
                        ></el-input-number>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column
                    align="center"
                    label="采购价"
                    prop="purchasePrice"
                  >
                    <template slot-scope="scope">
                      <el-form-item :prop="scope.row.purchasePrice">
                        <el-input-number
                          size="mini"
                          v-model="priceForm.purchasePrice"
                          controls-position="right"
                          :min="0.0"
                          :max="99999.99"
                          :precision="2"
                          placeholder="请录入"
                        ></el-input-number>
                      </el-form-item>
                    </template>
                  </el-table-column> -->
                  <el-table-column
                    align="center"
                    label="批发价"
                    prop="batchPrice"
                  >
                    <template slot-scope="scope">
                      <el-form-item :prop="scope.row.batchPrice">
                        <el-input-number
                          size="mini"
                          v-model="priceForm.batchPrice"
                          controls-position="right"
                          :min="0.0"
                          :max="99999.99"
                          :precision="2"
                          placeholder="请录入"
                        ></el-input-number>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column
                    align="center"
                    :label="item.name"
                    v-for="(item, index) in priceForm.unitTemplateList"
                    :key="index"
                    prop="item.price"
                  >
                    <template slot-scope="scope">
                      <el-form-item>
                        <el-input-number
                          size="mini"
                          v-model="item.price"
                          controls-position="right"
                          :min="0.0"
                          :max="99999.99"
                          :precision="2"
                          placeholder="请录入"
                        ></el-input-number>
                      </el-form-item>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-row>
          </el-form>
          <div style="width: 93%; margin: 0 auto" v-else class="productTabel">
            <div style="margin-bottom: 30px">
              <span style="margin-right: 10px">多计量单位</span>
              <el-checkbox
                v-model="isMultiUnit"
                @change="changeUnit"
              ></el-checkbox>
              <el-tooltip
                class="item"
                effect="dark"
                content="辅助单位与基本单位有一定的换算比例。 例如基本单位“瓶“，辅助单位”箱“，1箱=12瓶"
                placement="right"
              >
                <i class="el-icon-warning" style="margin-left: 20px"></i>
              </el-tooltip>
            </div>
            <div>
              <div class="tableName" style="margin-bottom: 20px">
                单位明细：
              </div>
              <div class="tableView">
                <el-form
                  :model="formData"
                  :rules="formData.rules"
                  ref="formRef"
                >
                  <el-table :data="formData.priceTable" ref="priceTable" border>
                    <el-table-column align="center" label="操作" fixed>
                      <template slot-scope="scope">
                        <el-form-item>
                          <div v-if="formData.priceTable.length === 1">
                            <el-button type="text" @click="addPrice"
                              >添加</el-button
                            >
                          </div>
                          <div v-else>
                            <!-- 多个单位 -->
                            <el-button
                              v-if="scope.row.isInit == '0'"
                              type="text"
                              @click="addPrice"
                              >添加</el-button
                            >
                            <el-button
                              v-if="
                                scope.row.isInit == '1' &&
                                formData.priceTable.length > 2
                              "
                              type="text"
                              @click="deletePrice(scope.$index)"
                              >删除</el-button
                            >
                          </div>
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="单位用途"
                      prop="isInit"
                      width="160"
                      fixed
                    >
                      <template slot-scope="scope">
                        <el-form-item style="">
                          <span>{{
                            scope.row.isInit == "1"
                              ? "辅助单位" + scope.$index
                              : "基本单位"
                          }}</span>
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column
                      align="center"
                      label="商品单位"
                      prop="name"
                      width="160"
                      fixed
                    >
                      <template slot="header">
                        <span style="color: red; margin-right: 5px">*</span
                        ><span>商品单位</span>
                      </template>
                      <template slot-scope="scope">
                        <el-form-item
                          style=""
                          :prop="'priceTable.' + scope.$index + '.name'"
                          :rules="formData.rules.name"
                        >
                          <el-input
                            v-if="scope.row.isInit == '1'"
                            size="small"
                            v-model="scope.row.name"
                          >
                          </el-input>
                          <el-select
                            v-if="scope.row.isInit == '0'"
                            v-model="scope.row.name"
                            style="width: 100%"
                            filterable
                            placeholder="请选择"
                          >
                            <el-button
                              type="text"
                              style="
                                width: 100%;
                                text-align: left;
                                padding-left: 20px;
                              "
                              @click="openUnitDialog"
                              >新增单位</el-button
                            >
                            <el-option
                              v-for="(item, Index) in units"
                              :key="Index"
                              :label="item.name"
                              :value="item.name"
                            />
                          </el-select>
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column
                      align="center"
                      label="换算关系"
                      prop="value"
                      width="160"
                      fixed
                    >
                      <template slot="header">
                        <span style="color: red; margin-right: 5px">*</span
                        ><span>换算关系</span>
                      </template>
                      <template slot-scope="scope">
                        <el-form-item style="" v-if="scope.$index === 0">
                          <el-input-number
                            size="mini"
                            v-model="scope.row.value"
                            controls-position="right"
                            :disabled="true"
                            :min="1"
                            :max="1"
                            :precision="0"
                            placeholder="请录入"
                          ></el-input-number>
                        </el-form-item>
                        <el-form-item
                          v-else
                          style=""
                          :prop="'priceTable.' + scope.$index + '.value'"
                          :rules="formData.rules.value"
                        >
                          <el-input-number
                            size="mini"
                            v-model="scope.row.value"
                            controls-position="right"
                            :min="2"
                            :max="999"
                            :precision="0"
                            placeholder="请录入"
                          ></el-input-number>
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <!-- <el-table-column
                      align="center"
                      label="采购价"
                      prop="purchasePrice"
                      width="160"
                      fixed
                    >
                      <template slot-scope="scope">
                        <el-form-item
                          style=""
                          :prop="
                            'priceTable.' + scope.$index + '.purchasePrice'
                          "
                          :rules="formData.rules.purchasePrice"
                        >
                          <el-input-number
                            size="mini"
                            v-model="scope.row.purchasePrice"
                            controls-position="right"
                            :min="0.0"
                            :max="99999.99"
                            :precision="2"
                            placeholder="请录入"
                          ></el-input-number>
                        </el-form-item>
                      </template>
                    </el-table-column> -->
                    <el-table-column
                      align="center"
                      label="零售价"
                      prop="price"
                      width="160"
                    >
                      <template slot="header">
                        <span style="color: red; margin-right: 5px">*</span
                        ><span>零售价</span>
                      </template>
                      <template slot-scope="scope">
                        <el-form-item
                          style=""
                          :prop="'priceTable.' + scope.$index + '.price'"
                          :rules="formData.rules.price"
                        >
                          <el-input-number
                            size="mini"
                            v-model="scope.row.price"
                            controls-position="right"
                            :min="0.01"
                            :max="99999.99"
                            :precision="2"
                            placeholder="请录入"
                          ></el-input-number>
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column
                      align="center"
                      label="批发价"
                      prop="batchPrice"
                      width="160"
                    >
                      <template slot-scope="scope">
                        <el-form-item
                          style=""
                          :prop="'priceTable.' + scope.$index + '.batchPrice'"
                          :rules="formData.rules.batchPrice"
                        >
                          <el-input-number
                            size="mini"
                            v-model="scope.row.batchPrice"
                            controls-position="right"
                            :min="0.0"
                            :max="99999.99"
                            :precision="2"
                            placeholder="请录入"
                          ></el-input-number>
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column
                      align="center"
                      :label="item.name"
                      v-for="(item, index) in unitTemplateList"
                      :key="index"
                      prop="item.price"
                      width="160"
                    >
                      <template slot-scope="scope">
                        <el-form-item>
                          <el-input-number
                            size="mini"
                            v-model="scope.row.unitTemplateList[index].price"
                            controls-position="right"
                            :min="0.0"
                            :max="99999.99"
                            :precision="2"
                            placeholder="请录入"
                            v-if="scope.row.unitTemplateList"
                          ></el-input-number>
                        </el-form-item>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-form>
              </div>
            </div>
          </div>
        </div>
      </mia-Card>
      <mia-Card v-if="Config.isEarly === '1'" title="库存预警">
        <div style="margin-left: 4px" slot="content">
          <el-form
            ref="wmsForm"
            :model="wmsForm"
            label-width="120px"
            :rules="wmsRules"
          >
            <el-row :gutter="50">
              <el-col :span="11">
                <el-form-item
                  label="库存上限"
                  prop="inventoryCeiling"
                  key="inventoryCeiling"
                >
                  <el-input
                    v-model.number="wmsForm.inventoryCeiling"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item
                  label="库存下限"
                  prop="inventoryFloor"
                  key="inventoryFloor"
                >
                  <el-input v-model.number="wmsForm.inventoryFloor"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </mia-Card>
      <mia-Card title="其它信息">
        <div style="margin-left: 4px" slot="content">
          <el-form
            ref="otherForm"
            :model="otherForm"
            label-width="120px"
            :rules="otherRules"
          >
            <el-row :gutter="50">
              <el-col :span="11">
                <el-form-item label="毒性" prop="toxicity" key="toxicity">
                  <el-select
                    v-model="otherForm.toxicity"
                    style="width: 100%"
                    allow-create
                    filterable
                    clearable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="(item, Index) in toxicityList"
                      :key="Index"
                      :label="item.label"
                      :value="item.label"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="剂型" prop="dosage" key="dosage">
                  <el-select
                    v-model="otherForm.dosage"
                    style="width: 100%"
                    allow-create
                    filterable
                    clearable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="(item, Index) in dosageList"
                      :key="Index"
                      :label="item.label"
                      :value="item.label"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="包装类型" prop="category" key="category">
                  <el-select
                    v-model="otherForm.category"
                    style="width: 100%"
                    allow-create
                    filterable
                    clearable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="(item, Index) in categoryList"
                      :key="Index"
                      :label="item.label"
                      :value="item.label"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item
                  label="生产企业"
                  prop="manufacturer"
                  key="manufacturer"
                >
                  <el-input v-model="otherForm.manufacturer"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="生产许可证号" prop="license" key="license">
                  <el-input v-model="otherForm.license"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item
                  label="产品标准号"
                  prop="standardNo"
                  key="standardNo"
                >
                  <el-input v-model="otherForm.standardNo"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </mia-Card>
    </div>
    <miaDialog ref="ScanForm" v-if="scanVisible">
      <div slot="content" style="width: 90%; margin: 0 auto">
        <el-form :model="scanCodeForm" label-width="130px" ref="scanCodeForm">
          <el-form-item label="二维码或登记证号" prop="code">
            <el-row>
              <el-col :span="16"
                ><el-input
                  v-model="scanCodeForm.code"
                  placeholder="点击此处扫描二维码或输入登记证号"
                ></el-input
              ></el-col>
              <el-col :span="4" :push="4"
                ><el-button type="primary" @click="GetInfo"
                  >查询</el-button
                ></el-col
              >
            </el-row>
          </el-form-item>
        </el-form>
      </div>
    </miaDialog>
  </div>
</template>
<script>
import { GetProductTypeList } from "@/api/productType";
import { GetProductUnitList, AddProductUnit } from "@/api/productUnit";
import {
  GetProductList,
  EditProduct,
  GetProductInfo,
  searchProductInfo,
  getYnyPriceTemplate,
  getDetails,
} from "@/api/pesticideProduct";
// import { GetParameter } from "@/api/system";
export default {
  data() {
    // 预警天数
    const validateDay = (rule, value, callback) => {
      if (value) {
        if (value >= this.BatchForm.expire) {
          callback(new Error("预警天数必须小于保质期天数"));
        } else if (Number(value) > 365 || Number(value) <= 0) {
          callback(new Error("天数应在0-365天之间"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      unitTemplateList: [],
      loading: false,
      // 禁用
      forbid: false,
      typeList: [],
      typeProps: {
        value: "id",
        label: "name",
        children: "list",
      },
      UnitList: [
        {
          id: 0,
          name: "毫升",
        },
        {
          id: 1,
          name: "克",
        },

        {
          id: 2,
          name: "千克",
        },
        {
          id: 3,
          name: "升",
        },
      ],
      LimitList: [
        { label: "否", value: "0" },
        { label: "是", value: "1" },
      ],
      toxicityList: [
        { label: "微毒", value: "微毒" },
        { label: "低度", value: "低度" },
        { label: "中等毒", value: "中等毒" },
        { label: "高度", value: "高度" },
        { label: "剧毒", value: "剧毒" },
      ],
      dosageList: [
        { label: "粉剂", value: "粉剂" },
        { label: "可湿性粉剂", value: "可湿性粉剂" },
        { label: "乳油", value: "乳油" },
        { label: "悬浮剂", value: "悬浮剂" },
        { label: "干悬浮剂", value: "干悬浮剂" },
        { label: "浓乳剂", value: "浓乳剂" },
        { label: "缓释剂", value: "缓释剂" },
        { label: "助剂", value: "助剂" },
      ],
      categoryList: [
        { label: "玻璃", value: "玻璃" },
        { label: "金属", value: "金属" },
        { label: "聚酯", value: "聚酯" },
        { label: "塑料袋", value: "塑料袋" },
        { label: "铝箔袋", value: "铝箔袋" },
        { label: "桶装", value: "桶装" },
        { label: "气雾罐", value: "气雾罐" },
      ],
      basicForm: {
        regCode: "",
        code: "",
        name: "",
        // abbreviation: "",
        typeId: "",
        barCode: "",
        // spec: "",
        netContent: "",
        netContentUnit: 0,
        category: "", // 包装类型
        shelfLife: "",
        isLimit: "0",
        manufacturer: "",
        license: "",
        effectiveContent: "",
        standardNo: "",
        inventoryCeiling: "",
        inventoryFloor: "",
      },
      rules: {
        regCode: [
          { required: true, message: "请填写登记证号", trigger: "blur" },
          {
            min: 1,
            max: 32,
            message: "名称长度在 1 到 32 个字符",
            trigger: "blur",
          },
        ],
        code: [
          {
            min: 1,
            max: 32,
            message: "名称长度在 1 到 32 个字符",
            trigger: "blur",
          },
        ],
        name: [
          { required: true, message: "请填写商品名称", trigger: "blur" },
          {
            min: 1,
            max: 64,
            message: "名称长度在 1 到 64 个字符",
            trigger: "blur",
          },
        ],
        spec: [
          {
            min: 0,
            max: 16,
            message: "字符长度在 0 到 16 个字符",
            trigger: "blur",
          },
        ],
        abbreviation: [
          {
            min: 1,
            max: 16,
            message: "名称长度在 1 到 16 个字符",
            trigger: "blur",
          },
        ],
        manufacturer: [
          {
            min: 1,
            max: 64,
            message: "长度在 1 到 64 个字符",
            trigger: "blur",
          },
        ],
        typeId: [
          { required: true, message: "请选择商品类别", trigger: "change" },
        ],
        netContent: [
          { required: true, message: "请填写净含量", trigger: "blur" },
          {
            type: "number",
            min: 1,
            max: 9999,
            message: "请输入1-9999内的数字",
            trigger: "blur",
          },
        ],
        isLimit: [{ required: true, message: "请选择", trigger: "change" }],
        barCode: [
          {
            min: 1,
            max: 32,
            message: "长度在 1 到 32 个字符",
            trigger: "blur",
          },
        ],
        license: [
          {
            min: 1,
            max: 32,
            message: "长度在 1 到 32 个字符",
            trigger: "blur",
          },
        ],
        standardNo: [
          {
            min: 1,
            max: 32,
            message: "长度在 1 到 32 个字符",
            trigger: "blur",
          },
        ],
        shelfLife: [
          { required: true, message: "请填写保质期天数", trigger: "blur" },
          {
            type: "number",
            min: 1,
            max: 3650,
            message: "请输入在1-3650天内的数字",
            trigger: "blur",
          },
        ],
      },
      wmsForm: {
        inventoryCeiling: "",
        inventoryFloor: "",
      },
      wmsRules: {
        inventoryCeiling: [
          {
            type: "number",
            min: 1,
            max: 9999,
            message: "请输入数字",
            trigger: "blur",
          },
        ],
        inventoryFloor: [
          {
            type: "number",
            min: 1,
            max: 9999,
            message: "请输入数字",
            trigger: "blur",
          },
        ],
      },
      otherForm: {},
      otherRules: {},
      // 是否多单位 0关闭 1开启
      isMultiUnit: false,
      priceForm: {
        isInit: 0,
        name: "",
        // purchasePrice: undefined,
        price: undefined,
        value: 1,
        batchPrice: undefined,
        // memberPrice: "",
      },
      priceRules: {
        name: [
          { required: true, message: "请填写商品单位", trigger: "blur" },
          {
            min: 1,
            max: 6,
            message: "名称长度在 1 到 6 个字符",
            trigger: "blur",
          },
        ],
        value: [{ required: true, message: "请填写换算关系", trigger: "blur" }],
        price: [{ required: true, message: "请填写零售价", trigger: "blur" }],
      },
      formData: {
        rules: {
          name: [
            { required: true, message: "请填写商品单位", trigger: "blur" },
            {
              min: 1,
              max: 6,
              message: "名称长度在 1 到 6 个字符",
              trigger: "blur",
            },
          ],
          value: [
            { required: true, message: "请填写换算关系", trigger: "blur" },
          ],
          price: [{ required: true, message: "请填写零售价", trigger: "blur" }],
        },
        priceTable: [
          {
            isInit: 0,
            name: "",
            value: 1,
            // purchasePrice: undefined,
            price: undefined,
            batchPrice: undefined,
            unitTemplateList: [
              {
                version: "",
              },
            ],
          },
        ],
      },
      // 批次管理
      isBatch: false,
      BatchForm: {
        expire: "",
        warnNum: "",
      },
      BatchRules: {
        expire: [
          { required: true, message: "请填写保质期天数", trigger: "blur" },
          {
            type: "number",
            min: 1,
            max: 3650,
            message: "天数在1-3650天内",
            trigger: "blur",
          },
        ],
        warnNum: [{ validator: validateDay, trigger: "blur" }],
      },
      isSerial: false,
      // 公司配置
      Config: {},
      id: null,
      scanVisible: false,
      scanCodeForm: {
        code: "",
      },
      typeId: [],
      units: [], // 单位集合
    };
  },
  mounted() {
    if (sessionStorage.getItem("status")) {
      this.scanVisible = true;
      this.$nextTick(() => {
        this.$refs.ScanForm.init("扫码添加商品");
      });
    }
  },
  watch: {
    isMultiUnit(val) {
      if (val && this.isSerial) {
        this.$message.error("已开启序列码管理，无法开启多计量单位");
        this.isMultiUnit = false;
      }
    },
    isSerial(val) {
      if (val && this.isMultiUnit) {
        this.$message.error("已开启多计量单位管理，无法开启序列码管理");
        this.isSerial = false;
      }
    },
  },
  beforeDestroy() {
    sessionStorage.removeItem("status");
  },
  async created() {
    // this.GetConfig();
    this.Config = JSON.parse(sessionStorage.getItem("config"));
  },
  methods: {
    // 获取预设单位
    async GetProductUnitList() {
      const res = await GetProductUnitList();
      if (res.code === 1) {
        this.units = res.data;
      }
    },
    Pricechange(currentValue, oldValue) {
      console.log(currentValue, oldValue);
    },
    // 多计量
    changeUnit(val) {
      if (this.isSerial) {
        this.$message.error("已开启序列码管理，无法开启多计量单位");
        this.isMultiUnit = false;
      }
      if (!this.isMultiUnit) {
        this.formData.priceTable = [
          {
            isInit: 0,
            name: "",
            value: 1,
            // purchasePrice: undefined,
            price: undefined,
            batchPrice: undefined,
            unitTemplateList: this.unitTemplateList,
          },
        ];
        if (
          !this.priceForm.unitTemplateList ||
          this.priceForm.unitTemplateList.length == 0
        ) {
          this.priceForm.unitTemplateList = this.unitTemplateList;
        }
      } else {
        //  this.formData.priceTable = this.basicForm.unitList;
        if (val && this.formData.priceTable.length === 1) {
          this.addPrice();
        }
      }

      this.$forceUpdate();
    },
    //获取商品详情信息
    // async getDetails(){
    //   let params = {
    //     productId:this.id
    //   }
    //   let res = await getDetails(params);
    //   if(res.code == 1){

    //   }
    // },
    //获取预售价格模板
    async getYnyPriceTemplate() {
      let res = await getYnyPriceTemplate();
      if (res.code == 1) {
        this.unitTemplateList = res.data;
        this.formData.priceTable[0].unitTemplateList = JSON.parse(
          JSON.stringify(this.unitTemplateList)
        );
      }
    },
    //登记证号查询商品
    async searchProductInfo() {
      let res = await searchProductInfo(this.basicForm.regCode);
      if (res.code == 1) {
        this.basicForm = { ...this.basicForm, ...res.data };
      }
    },
    openUnitDialog() {
      this.$prompt("请输入名称", "新增单位", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        // inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
        // inputErrorMessage: '邮箱格式不正确'
      })
        .then(({ value }) => {
          this.addUnit(value);
        })
        .catch(() => {});
    },
    addUnit(val) {
      AddProductUnit({ name: val }).then((res) => {
        if (res.code === 1) {
          this.$message.success("添加单位成功");
          this.GetProductUnitList();
        }
      });
    },
    async init(id) {
      this.id = id;
      if (this.id) {
        if (this.Config.isMemberPrice == "1") {
          await this.getYnyPriceTemplate();
        }
        await this.GetProductUnitList();
        this.getType();
        this.getData();
        // this.getDetails();
      }
    },
    getData() {
      getDetails({
        productId: this.id,
      }).then((res) => {
        if (res.code === 1) {
          // const { records } = res.data;
          // const data = records.filter((val) => {
          //   return val.id === this.id;
          // });
          this.basicForm = res.data;
          this.otherForm = {
            toxicity: res.data.toxicity,
            dosage: res.data.dosage,
            category: res.data.category,
            manufacturer: res.data.manufacturer,
            license: res.data.license,
            standardNo: res.data.standardNo,
          };
          this.wmsForm = {
            inventoryCeiling: res.data.inventoryCeiling,
            inventoryFloor: res.data.inventoryFloor,
          };
          this.isMultiUnit = this.basicForm.isMultiUnit == "1" ? true : false;
          // this.isBatch = this.basicForm.isBatch ? true : false;
          this.isSerial = this.basicForm.isSerial ? true : false;
          if (!this.isMultiUnit) {
            let tempList = [];
            if (res.data.unitList[0].unitTemplateList) {
              tempList = res.data.unitList[0].unitTemplateList.map((u) => {
                return {
                  ...u,
                  name: this.unitTemplateList.filter(
                    (p) => p.id === u.templateId
                  )[0].name,
                  price: u.price ? u.price / 100 : undefined,
                };
              });
            } else {
              tempList = this.unitTemplateList;
            }
            const priceform = this.basicForm.unitList[0];
            this.priceForm = {
              ...priceform,
              price:
                priceform.price != undefined
                  ? priceform.price / 100
                  : undefined,
              // purchasePrice:
              //   priceform.purchasePrice != undefined
              //     ? priceform.purchasePrice / 100
              //     : undefined,
              batchPrice:
                priceform.batchPrice != undefined
                  ? priceform.batchPrice / 100
                  : undefined,
              unitTemplateList: tempList,
            };
            // this.unitTemplateList = this.basicForm.unitList[0].unitTemplateList;
          } else {
            this.formData.priceTable = this.basicForm.unitList.map((item) => {
              item.price =
                item.price != undefined ? item.price / 100 : undefined;
              item.batchPrice =
                item.batchPrice != undefined
                  ? item.batchPrice / 100
                  : undefined;
              // item.purchasePrice =
              //   item.purchasePrice != undefined
              //     ? item.purchasePrice / 100
              //     : undefined;
              item.unitTemplateList = item.unitTemplateList.map((u) => {
                u.price = u.price != undefined ? u.price / 100 : undefined;
                return u;
              });
              return item;
            });
          }
        }
        // else {
        //   this.$message.error(res.msg);
        // }
      });
    },
    GetConfig() {
      GetParameter().then((res) => {
        const { code, data, msg } = res;
        if (code === 1) {
          this.Config = { ...data };
        }
      });
    },
    getType() {
      GetProductTypeList().then((res) => {
        if (res.code === 1) {
          const { records, current, total } = res.data;
          this.typeList = records;
          this.getTreeData(this.typeList);
        } else {
          this.$message.error(res.msg);
        }
        this.loading = false;
      });
    },
    getTreeData(data) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].list.length < 1) {
          // list若为空数组，则将删除
          delete data[i].list;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          this.getTreeData(data[i].list);
        }
      }
      return data;
    },
    submitForm() {
      if (this.isMultiUnit && this.isSerial) {
        // 当多单位和序列码同时开启时
        this.$message.error("单个商品上不能同时存在多单位管理和序列码管理");
        return;
      }
      this.$refs["basicForm"].validate((valid) => {
        if (valid) {
          // 是否开启了多计量单位
          if (this.isMultiUnit) {
            // 校验多单位表格表单
            this.$refs["formRef"].validate((valid) => {
              if (valid) {
                if (this.isBatch) {
                  this.$refs["BatchForm"].validate((valid) => {
                    if (valid) {
                      this.SaveData();
                    } else {
                      return false;
                    }
                  });
                } else {
                  this.SaveData();
                }
              } else {
                return false;
              }
            });
          } else {
            // 校验多单位表单
            this.$refs["priceForm"].validate((valid) => {
              if (valid) {
                if (this.isBatch) {
                  this.$refs["BatchForm"].validate((valid) => {
                    if (valid) {
                      this.SaveData();
                    } else {
                      return false;
                    }
                  });
                } else {
                  this.SaveData();
                }
              } else {
                return false;
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    addPrice() {
      if (this.formData.priceTable.length >= 3) {
        this.$message.error("一个基本单位最多有两个辅助单位");
      } else {
        const obj = {
          isInit: 1,
          name: "",
          value: 1,
          // purchasePrice: undefined,
          price: undefined,
          batchPrice: undefined,
          unitTemplateList: JSON.parse(JSON.stringify(this.unitTemplateList)),
        };
        this.formData.priceTable.push(obj);
      }
    },
    deletePrice(index) {
      this.formData.priceTable.splice(index, 1);
    },
    SaveData() {
      this.loading = true;
      let form = { ...this.basicForm, ...this.otherForm, ...this.wmsForm };
      if (!this.isMultiUnit) {
        form.isMultiUnit = 0;
        form.unitList = [this.priceForm];
      } else {
        form.isMultiUnit = 1;
        form.unitList = JSON.parse(JSON.stringify(this.formData.priceTable));
      }
      if (this.isBatch) {
        form.isBatch = 1;
      } else {
        form.isBatch = 0;
      }
      if (this.isSerial) {
        form.isSerial = 1;
      } else {
        form.isSerial = 0;
      }
      form.batch = { ...this.BatchForm };
      if (this.typeId.length > 1) {
        form.typeId = this.typeId[this.typeId.length - 1];
      } else {
        form.typeId = this.basicForm.typeId;
      }
      form.id = this.id;
      if (!this.isMultiUnit) {
        form.isMultiUnit = 0;
        //   form.unitList = [this.priceForm];
        //   let arr = [];
        // //  form.unitList.forEach((item) => {
        // //     item.price = item.price;
        // //   });
        //   this.priceForm.unitTemplateList.forEach((item) => {
        //     let obj = {};
        //     obj.templateId = item.id || item.templateId;
        //     obj.price = item.price!=undefined?item.price*100:'';
        //     arr.push(obj);
        //   });
        //   form.unitList.forEach((item) => {
        //     item.unitTemplateList = arr;
        //   });
        const commitPriceForm = {
          ...this.priceForm,
          price: this.priceForm.price ? this.priceForm.price * 100 : undefined,
          // purchasePrice: this.priceForm.purchasePrice
          //   ? this.priceForm.purchasePrice * 100
          //   : undefined,
          batchPrice: this.priceForm.batchPrice
            ? this.priceForm.batchPrice * 100
            : undefined,
        };
        form.unitList = [commitPriceForm];
        let arr = [];
        // this.unitTemplateList.forEach((item) => {
        //   item.price = item.price!=undefined?item.price:'';
        // });
        this.priceForm.unitTemplateList.forEach((item) => {
          let obj = {};
          obj.templateId = item.id || item.templateId;
          obj.price = item.price != undefined ? item.price * 100 : "";
          arr.push(obj);
        });
        form.unitList.forEach((item) => {
          item.unitTemplateList = arr;
        });
      } else {
        form.isMultiUnit = 1;
        this.formData.priceTable.forEach((item, index) => {
          if (item.price != undefined) {
            item.price = item.price * 100;
          }
          if (item.batchPrice != undefined) {
            item.batchPrice = item.batchPrice * 100;
          }
          // if (item.purchasePrice != undefined) {
          //   item.purchasePrice = item.purchasePrice * 100;
          // }
          let arr = [];
          item.unitTemplateList.forEach((items, indexs) => {
            let obj = {};
            obj.templateId = items.id || items.templateId;
            obj.price = items.price != undefined ? items.price * 100 : "";
            arr.push(obj);
          });
          item.unitTemplateList = arr;
        });

        form.unitList = this.formData.priceTable;
      }
      EditProduct(form).then((res) => {
        const { code, msg } = res;
        this.loading = false;
        if (code === 1) {
          this.$message.success(msg);
          this.$emit("back");
          this.$emit("refresh");
        } else {
          this.$message.error(msg);
        }
      });
    },
    back() {
      this.$emit("back");
    },
    // 查询
    GetInfo() {
      if (this.scanCodeForm.code !== "") {
        GetProductInfo(this.scanCodeForm.code).then((res) => {
          if (res.code === 1) {
            this.basicForm = { ...res.data };
            this.$refs.ScanForm.close();
            // 商品属性
            this.basicForm.toxicity = this.toxicityList.filter((val) => {
              return val.label === res.data.toxicity;
            })[0].value;
            this.basicForm.dosage = this.dosageList.filter((val) => {
              return val.label === res.data.toxicity;
            })[0].value;
            this.basicForm.category = this.categoryList.filter((val) => {
              return val.label === res.data.toxicity;
            })[0].value;
            sessionStorage.removeItem("status");
            this.forbid = true;
          } else {
            this.$message.error("未找到相关产品");
          }
        });
      } else {
        this.$message.warning("请输入登记证号");
      }
    },
    handleChange(value) {
      this.typeId = value;
    },
  },
};
</script>
